import React from "react"
import greenEconomy from "../../images/greenEconomy.jpg"
import reasonEsg from "../../images/business-talks.jpg"
import greenJob from "../../images/group-business.jpg"
import importance from "../../images/importance.jpg"
import COSOICSR from "../../pdfs/COSO-ICSR-Report.pdf"
export default function BuildingASustainableFuture(props) {
  return (
    <section className="container pt-5 pb-5 ignore-width-constraint">
      <div>
        <div>
          {/* <img className="wrap-img" src={importance} alt={``} /> */}
        </div>
        <p className="warp-img-text">
          The COSO-ICSR Report aims to communicate the significance of
          implementing the COSO Framework for Internal Controls over
          Sustainability Reporting (ICSR), which represents a significant
          milestone following the implementation of COSO's Internal Controls
          over Financial Reporting (ICFR) in 2013. Within an organization,
          sustainability processes and reporting are seamlessly integrated into
          various operational aspects, making it an extension of financial
          reporting.
        </p>
        <p className="warp-img-text">
          This integration necessitates the implementation of numerous
          interrelated control activities as an integral part of the
          sustainability reporting process.
        </p>
        <p className="warp-img-text">
          As per the SBEI guidelines, sustainability reporting has become
          mandatory for the top 1000 listed companies and the top 150 companies
          in the BRSR Core for the fiscal year 2024-2025. Consequently, it
          becomes crucial for organizations to establish, monitor, evaluate, and
          report on internal controls specifically designed for sustainability
          reporting on a regular basis.
        </p>
        <p className="warp-img-text">
          While reporting serves as the ultimate outcome, it is the controls
          that determine the sustainability of an organization. Therefore, both
          controls and reporting hold equal importance in establishing a
          sustainable business for the future. Given that investors, regulators,
          financial institutions, and other stakeholders seek assurance
          regarding sustainability reporting, it becomes imperative for top
          management to provide assurance on the effectiveness of controls and
          report them accordingly. Effectively managing sustainability risks
          relies on the assurance and reporting of controls alongside the
          reported information.
        </p>
        <p className="warp-img-text">
          To sum up, ESG is crucial for businesses because it raises awareness
          of many climate challenges and pushes firms to adopt practises and
          regulations that are better for the environment, society, and
          corporate governance.
        </p>
        <p className="warp-img-text">
          The risk assessment and internal controls framework for sustainability
          reporting can differ among organizations, depending on their
          materiality and scale of operations. Each organization has the
          flexibility to develop a customized plan to strengthen controls over
          sustainability reporting at a pace that aligns with its specific
          needs. As regulations pertaining to sustainability reporting continue
          to evolve, assurance becomes increasingly important in instilling
          confidence among investors, regulators, and all stakeholders, not only
          in financial reporting but also in sustainable reporting.
        </p>

        <a
          href={COSOICSR}
          target="_blank"
          className="btn learn-more-btn my-4"
          style={{ marginRight: "5px" }}
        >
          CLICK TO DOWNLOAD THE COSO ICSR REPORT
        </a>
      </div>
    </section>
  )
}
